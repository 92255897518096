/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout'
import { ScrollContainer } from '../context/scrollBehavior'
import SEO from '../components/SEO'
import AnimateOnEntrance from '../components/fx/animate/AnimateOnEntrance'

// determine image sourcing by env
import useAuth from '../util/hooks/useAuth'
import { confirmAccount } from '../util/auth/register';
import ImageInView from '../components/fx/animate/ImageInView';

const ConfirmAccountPage = ({data}) => {
    
    useAuth();
    const [device, setDevice ] = useState('mobile')
    const [confirmed, setConfirmed] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const submitValidationConfirmation = async () => {
            // get path name
            const path = window.location.pathname.split("/");
            const hash = path.slice(-1).pop();

            const response = await confirmAccount(hash);
            if (response.error === true) {
                setError(response.msg);
            } else {
                setConfirmed(true);
            }
        }

        submitValidationConfirmation();
    }, []);
    
    return (
        <>
        <SEO title="Confirm Your Account" />
        <Layout device={device} setDevice={setDevice} logo={data.logo} menu={data.allMenuMenuLink} footerMenu={data.footer_menu} locations={data.locations.edges}>
            <ScrollContainer force={true}>  
                <section className="grid-section register-signup grid-content-container t-left">
                    <div className="t-content-container t-left">
                        <div className="grid-2 grid-justify-center grid-align-center grid-gap-lg">
                            <div className="grid-col">
                                <div className="t-heading-container">
                                    <AnimateOnEntrance className="fadeInLeft" device={device}>
                                        {confirmed ? (
                                            <h3 className="t-mxlarge t-heading">Account Successfully Confirmed!</h3>
                                        ) : (
                                            <h3 className="t-mxlarge t-heading">Confirming your account...</h3>
                                        )}
                                    </AnimateOnEntrance>
                                    <div className="t-content-container t-left t-callout">
                                        <AnimateOnEntrance className="fadeInLeft" device={device}>
                                            {confirmed ? (
                                                <p className="success">
                                                    <strong>
                                                        Your account was successfully confirmed! Please log in. 
                                                    </strong>
                                                </p>
                                            ) : (
                                                <p>
                                                    Please wait one moment while we confirm your account.
                                                </p>
                                            ) }
                                            {error ? (
                                                <p>
                                                    <span className="error">{error}</span>
                                                </p>
                                            ) : null}
                                        </AnimateOnEntrance>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-col">
                                <AnimateOnEntrance className="fadeInRight" device={device}>
                                    <ImageInView src={"/media/su_grid.jpg"} />
                                </AnimateOnEntrance>
                            </div>
                        </div>
                    </div>
                </section>
            </ScrollContainer>
        </Layout>
        </>
    )
}

export default ConfirmAccountPage

export const ConfirmQuery = graphql`
query ConfirmQuery {
    allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "gatsby-menu"}}) {
        edges {
            node {
            enabled
            title
            expanded
            external
            langcode
            weight
            link {
                uri
            }
            drupal_parent_menu_item
            bundle
            drupal_id
            menu_name
            }
        }
    }   
    locations: allNodeLocation {
        edges {
        node {
            field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
            }
            field_address_description
            field_address_short
            field_location_email
            field_location_fax_number
            field_location_phone_number
            title
            field_office_hours_description
        }
        }
    }
    footer_menu: allMenuMenuLink(sort: {fields: [weight], order: ASC}, filter: {menu_name: {eq: "footer"}}) {
        edges {
            node {
                enabled
                title
                expanded
                external
                langcode
                weight
                link {
                    uri
                }
                bundle
                drupal_id
                menu_name
            }
        }
    }
}`